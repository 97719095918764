@font-face {
  font-family: desc;
  src: url("../../../fonts/Montserrat-Light.ttf");
}

.ad-youradhere {
  background-color: #020c53;
  padding-top: 20px;
}

.ad-youradhere .leftImg {
  width: 21rem;
}

.ad-youradhere .rightContent {
  width: 30rem;
  font-family: desc;
}
.ad-youradhere .rightContent p {
  line-height: 24px;
 
}

@media screen and (min-width: 280px) and (max-width: 767px) {
  .ad-youradhere .leftImg {
    width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .ad-youradhere .rightContent {
    width: 35rem;
  }
}
