@font-face{
    font-family: title;
    src: url("../../../fonts/Montserrat-Bold.ttf");
  }



.problemSection{
    background-image: url(../../../img/home/problem/Rectangle.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .problemSection .problemSection-underline{
    display: table;
    margin-inline:auto;
    margin-top:35px;
    font-family: title;
    
  }
  .problemSection .problemSection-underline::after{
    border-bottom: 2px solid #FFCA3D;
    content: '';
    display: block;
    width: 85px;
   
    margin-inline:auto;
  }
.problemSection .titile{
    font-size: 48px;
    font-weight: 800;
}

/* .problemSection .horizontalLine{
    width: 100px;
    margin: 0 auto;
    border: 2px solid #FFCA3D;
} */

.problemSection .card{
    max-width: 24rem;
}

.problemSection .card .card-header:first-child{
    background-color: #D94E0C;
    height: 70px;
    line-height:70px;
    text-align: center;
    font-size: 23px;
    color: #FFFFFF;
    font-family: title;
}

.problemSection .card .list-group .listRightDesc{   
    font-weight: 600;
    line-height: 18px;
    color: #020C53;
    font-size: 13px;
}
.problemSection .card .list-group .list-group-item img{   
    width: 50px;
}

.problemSection .endUserCardWrapper{
    display: inline-flex;
    align-items: flex-start;
    justify-content: end;
}

@media screen and (min-width: 280px) and (max-width: 767px) {
    .problemSection .problemSection-underline::after{
       
        padding: 10px;
        margin-inline:auto;
      }
    .problemSection .card{
        width: 100%;
        max-width: 100%;
    }
    .problemSection .endUserCardWrapper{
        display: inline-flex;
        align-items: flex-start;
        justify-content: center;
    }

    .problemSection .titile{
        font-size: 26px;
        line-height:6px ;
    }
    .problemSection .card .card-header:first-child{
        font-size: 15px;
        line-height: 35px;
        height: 50px;
    }

    .problemSection .card .list-group .listRightDesc{   
        line-height: 14px;
        font-weight: 600;
        line-height: 20px;
    }
    .problemSection{
        padding-top: 50px;
        padding-bottom: 1px;
    }


}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .centerBox{
        display: flex;
        justify-content: center;
    }
    .problemSection .card{
        max-width: 24rem;
        min-width:24rem;
    }
    .problemSection .endUserCardWrapper{
        display: inline-flex;
        align-items: flex-start;
        justify-content: center;
    }
    .problemSection{
        padding-top: 50px;
        padding-bottom: 20px;
    }
    /* .problemSection .facilityCardWrapper{
        margin-top: 20px;
     } */
}

