@font-face {
    font-family: desc;
    src: url("../../../fonts/Montserrat-ExtraLight.ttf");
  }

.stadium{
    background-color: #020C53;
    padding-top: 50px;
    padding-bottom: 50px;
}

.stadium .rightImg{
    width: 440px;
}

.stadium .leftContent{
    width: 460px;
    font-family: desc;
    font-size: 16px;
    font-weight: 600;
}


@media screen and (min-width: 280px) and (max-width: 767px) {
    .stadium .leftContent{
        font-size: 14px;
        font-weight: 500;
    }
}