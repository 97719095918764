@font-face {
    font-family: desc;
    src: url("../../../fonts/Montserrat-ExtraLight.ttf");
  }

.sportsFacilities{
    padding-top: 50px;
    padding-bottom: 50px;
}

.sportsFacilities .leftImg{
    width: 440px;
}

.sportsFacilities .rightContent{
    width: 460px;
    font-family: desc;
    font-weight: 600;
    font-size: 16px;
}

@media screen and (min-width: 280px) and (max-width: 767px) {
    .sportsFacilities{
        padding-top: 15px;
        padding-bottom:20px;
    }
    .sportsFacilities .rightContent{
        font-size: 14px;
        font-style: normal;
    }

}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .sportsFacilities{
        padding-top: 30px;
        padding-bottom:30px;
    }
}
