@font-face{
  font-family: title;
  src: url("../../../fonts/Montserrat-ExtraBold.ttf");
}

@font-face{
  font-family: desc;
  src: url("../../../fonts/Montserrat-Light.ttf");
}


.solution {
  padding: 10px;
  max-width: 470px;
}


.solution .solution-underline:after{
  border-bottom: 2px solid #020C53;
  content: '';
  display: block;
  width: 100%;
  padding-top: 10px;
}

.solution img {
  width: 50px;
}

.solution h1 {
  display: inline-block;
  font-family: title;
}

.solution .solutionTitle {
  font-weight: 800;
  color: #020c53;
  font-size: 48px;
}

.solution .solutionDesc {
  font-family: desc;
  font-weight: 600;
  color: #4b4d58;
}

.solutionRightImage {
  justify-content: center;
}

.solutionRightImage img {
  width: 75%;
  object-fit: cover;
  z-index: 1;
}

.lampVector{
  width:230px;
  right: 10%;
  bottom: 5%;
  overflow: hidden;
}
@media screen and (min-width: 280px) and (max-width: 767px) {
  .solutionRightImage img {
    width: 100%
  }
  .solution img {
    width: 30px;
  }
  .solution .solutionTitle {
    font-size: 28px;
  }
  .solution .solutionDesc {
    
    font-size: 14px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1024px)  {
  .solutionRightImage img {
    width: 100%
  }
  .lampVector{
    width: 200px;
    right: -6%;
    bottom: 4%;
    overflow: hidden;
  }
}



@media screen and (min-width: 1025px) {
  .solutionRightImage img {
    width: 75%;
  object-fit: cover;
  }
}
