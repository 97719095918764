@font-face {
    font-family: pageTitle;
    src: url("../../../fonts/Montserrat-ExtraBold.ttf");
  }

.ad-audience{
    background-color: #020C53;
    padding-top: 30px;
    padding-bottom: 30px;
}

.ad-audience h3{
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 0;
    font-family: pageTitle;
}

@media screen and (min-width: 280px) and (max-width: 767px) {
    .ad-audience{
        padding-top: 5px;
        padding-bottom: 20px;
    }
    .ad-audience h3 br{
        display: none;
    }
    .ad-audience h3{
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 0;
    }
}