@font-face {
  font-family: Montserrat-Regular;
  src: url("../../../fonts/Montserrat-Regular.ttf");
}
@font-face {
  font-family: Montserrat-Light;
  src: url("../../../fonts/Montserrat-Light.ttf");
}

.partnerfooter {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #fff;
  font-family: Montserrat-Regular;
  font-weight: bold;
  font-size: 13px;
}

.partnerfooter .itemWrapper {
  display: flex;
  align-items: baseline;
}
.partnerfooter .imgDiv img {
  width: 18px;
}

.partnerfooter #clockImg {
  width: 17px;
}

.partnerfooter #commentImg {
  width: 19px;
}

@media screen and (min-width: 280px) and (max-width: 767px) {
  .partnerfooter {
    font-family: Montserrat-Light;
    font-size: 9px;
    font-weight: 800;
  }
  .partnerfooter .imgDiv img {
    width: 14px;
  }
  .partnerfooter #clockImg {
    width: 14px;
  }

  .partnerfooter #commentImg {
    width: 16px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .partnerfooter {
    font-size: 11px;
  }
}
