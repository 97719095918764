@font-face{
  font-family: labelFont;
  src: url("../../../fonts/Montserrat-ExtraBold.ttf");
}

.letTalk {
  background-color: #ffca3d;
  padding-top: 50px;
  padding-bottom: 50px;
}

.letTalk .form-select option{
  overflow: hidden;
  word-wrap: break-word;
  white-space: break-spaces;
  display: grid;
}

.letTalk .letTalk-underline::after{
  border-bottom: 2px solid #020C53;
  content: '';
  display: block;
  width: 85px;
}


.letTalk .letTalkWrapper {
  width: 460px;
}

.letTalk .titile {
  font-size: 48px;
  font-weight: 800;
  color: #020c53;
  position: relative;
  font-family: title;
}

.letTalk .horizontalLine {
  width: 100px;
  border: 1px solid #020c53;
}

.letTalk p {
  color: #020c53;
}
.letTalk span {
  font-weight: 500;
  color: #d94e0c;
}

.letTalk .form-control.is-invalid{
  border: 2px solid #dc3545 !important;
  box-shadow: none;
  background-image: none;
}

.letTalk .form-control:focus{
  box-shadow: none;
}

.letTalk .form-control.is-invalid:focus{
  box-shadow: none;
}

.letTalk .form-label {
  color: #2d2929;
  font-weight: 800;
  font-family: labelFont;
  font-size: 18px;
}

.letTalk .centerForm .formsWrappers .formBtn{
  background-color: #D94E0C;
  box-shadow: 6px 7px 18px rgba(217, 78, 12, 0.35); 
}

.letTalk .centerForm .formsWrappers .formBtn:hover{
  background: #020C53;
box-shadow: 6px 7px 18px rgba(2, 12, 83, 0.15);
}

.letTalk .form-control,.form-select{
  height: 50px;
}

.letTalk .formsWrappers {
  width: 28rem;
}

.leftColImage {
  display: block;
}

.rightColImg {
  display: none;
}

.letTalk .spinner-border{
  border: 0.25em solid rgba(255, 255, 255, 0.3);
  border-top-color: #FFCA3D;
  border-right-color: #FFCA3D;
  vertical-align: -0.3em;
  height: 20px;
  width: 20px;
}
.letTalk .invalid-feedback{
  font-style: italic;
}
@media screen and (min-width: 280px) and (max-width: 767px) {
  

  .letTalk .leftColImage {
    display: none;
  }
  .letTalk .rightColImg {
    display: block;
  }
  .centerForm {
    display: flex;
    justify-content: center;
  }
  .letTalk .letTalkWrapper {
    width: auto;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .letTalk .leftColImage {
    display: none;
  }
  .letTalk .rightColImg {
    display: block;
  }
  .centerForm {
    display: flex;
    justify-content: center;
  }
}
