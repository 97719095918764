@font-face {
  font-family: title;
  src: url("../../../fonts/Montserrat-ExtraBold.ttf");
}

@font-face {
  font-family: desc;
  src: url("../../../fonts/Montserrat-ExtraLight.ttf");
}


.business-joinus {
  background-color: #ffca3d;
  padding-top: 35px;
  min-height: 350px;
}

.business-joinus .business-joinus-underline {
  color: #020c53;
  display: table;
  margin-left: auto;
  margin-right: auto;
  font-family: title;
  font-size: 48px;
}

.business-joinus  h6 {
  font-family: title;
}

.business-joinus .business-joinus-underline:after {
  border-bottom: 2px solid #020c53;
  content: "";
  display: block;
  width: 85px;
  padding: 1px;
  margin-left: auto;
  margin-right: auto;
}

.business-joinus .card {
  max-width: 10.625rem;
  min-width: 10.625rem;
  min-height: 10rem;
  max-height: 10rem;
  margin: 10px;
  margin-left: 2px;
}

.business-joinus .card:hover{
  cursor: pointer;
}

.business-joinus .card .card-body {
  color: #020c53;
  height: 100px;
  font-family: title;
  
}

.business-joinus .card .card-body .cardImg {
  height: 80px;
  width: 80px;
  overflow: hidden;
}

.business-joinus .card .card-body .card-title {
  font-size: 15px;
}

@media screen and (min-width: 280px) and (max-width: 767px) {
  .business-joinus .business-joinus-underline {
    font-size: 26px;
  }
  .business-joinus {
    background-color: #ffca3d;
    padding-bottom: 35px;
  }

  .business-joinus  h6 {
    font-family: desc;
    font-weight: 500;
  }

  .business-joinus .card {
    max-width: 7.625rem;
    min-width: 7.625rem;
    min-height: 8rem;
    max-height: 8rem;
    margin: 5px;
    margin-left: 6px;
    margin-bottom: 5px;
}
  
  .business-joinus .card .card-body {
    color: #020c53;
    height: 100px;
  }
  
  .business-joinus .card .card-body .cardImg {
    height: 50px;
    width: 50px;
    overflow: hidden;
  }
  
  .business-joinus .card .card-body .card-title {
    font-size: 10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .business-joinus .card {
    max-width: 10.625rem;
    min-width: 10.625rem;
    min-height: 10rem;
    max-height: 10rem;
    margin: 10px;
    margin-left: 2px;
    margin-bottom: 2px;
}
}
@media screen and (min-width: 1024px) and (max-width:1199px)  {
  .business-joinus .card{
    max-width: 9rem;
    min-width: 9rem;
  }
}
