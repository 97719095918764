@font-face{
  font-family: title;
  src: url("../../../fonts/Montserrat-ExtraBold.ttf");
}

@font-face{
  font-family: reguler;
  src: url("../../../fonts/Montserrat-Medium.ttf");
}

@font-face{
  font-family: Montserrat-Regular;
  src: url("../../../fonts/Montserrat-Regular.ttf");
}


.proposition {
  /* min-height: 700px; */
  padding-top: 50px;
  padding-bottom: 30px;
}

.proposition .proposition-underline:after {
    border-bottom: 2px solid #020c53;
    content: '';
    display: block;
    width: 85px;
    margin-inline: auto;
  }

.proposition .titile {
  font-family: title;
  font-size: 48px;
  font-weight: 800;
  color: #020c53;
}

.proposition .horizontalLine {
  width: 100px;
  margin: 0 auto;
  border: 1px solid #020c53;
}

.proposition .featuresCard {
  width: 450px;
  background-color: #f9f9f9;
  padding: 25px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.proposition .benefitsCard {
  width: 450px;
  background-color: #f2f2f2;
  padding:25px;
  padding-left: 40px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.proposition .divider img{
  width: 175px;
}
.proposition .divider {
  top: 35%;
  left: 44%;
  z-index: 11;
}

.proposition .marginTop {
  margin-top: 30px;
}

.proposition .marginTop h5 {
  font-weight: 800;
  font-size: 24px;
  color: #020c53;
  /* margin-top: 30px; */
}
.proposition .marginTop .underline {
  display: table;
  font-family: title;
}

.underline:after {
  border-bottom: 3px solid #020c53;
  content: "";
  display: block;
  width: 40%;
}

.w-32 {
  width: 36px !important;
  height: 36px !important;
}
.tl-item .avatar {
  z-index: 2;
}
.circle {
  border-radius: 500px;
}

.timeline {
  position: relative;
  border-color: rgba(160, 175, 185, 0.15);
  padding: 0;
  margin: 0;
}
.p-4 {
  padding: 1.5rem !important;
}
.block,
.card {
  background-color: #fff;
  border-width: 0;
  border-radius: 0.25rem;
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05); */
  margin-bottom: 1.5rem;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.tl-item {
  border-radius: 3px;
  position: relative;
  display: -ms-flexbox;
  display: flex;
}
.tl-item > * {
  padding: 8px;
  color: #585858;
  font-family: Montserrat-Regular;
}
.tl-item .avatar {
  z-index: 2;
}
.tl-item:last-child .tl-dot:after {
  display: none;
}

.tl-item:last-child .tl-dot:after {
  display: none;
}

.tl-dot {
  position: relative;
  border-color: #585858;
}
.tl-dot:after {
  content: "";
  position: absolute;
  border-color: #585858;
  border-width: 2px;
  border-style: dashed;
  left: 50%;
  transform: translateX(-50%);
}
.tl-dot:after {
  width: 0;
  height: auto;
  top: 25px;
  bottom: -15px;
  border-right-width: 0;
  border-top-width: 0;
  border-bottom-width: 0;
  border-radius: 0;
}

.tl-dot {
  position: relative;
  border-color: #585858;
}
.tl-dot:after {
  content: "";
  position: absolute;
  border-color: inherit;
}
.tl-dot:after {
  width: 0;
  height: auto;
  top: 45px;
  bottom: -9px;
  border-right-width: 0;
  border-top-width: 0;
  border-bottom-width: 0;
  border-radius: 0;
}

.tl-content p:last-child {
  margin-bottom: 0;
  
}

.featuresCard .tl-item{
  margin-right: 20%;
}
.benefitsCard .tl-item{
  margin-left: 10%;
}

.benefitsCard h5{
  margin-left: 13% !important;
}



.avatar {
  position: relative;
  line-height: 1;
  border-radius: 500px;
  white-space: nowrap;
  font-weight: 700;
  border-radius: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-radius: 500px;
  border: 1px solid #585858;
  
}

@media screen and (min-width: 280px) and (max-width: 767px) {
  
  .proposition .divider img{
    width: 160px;
  }
  .featuresCard .tl-item{
    margin-right: 0;
  }
  .benefitsCard .tl-item{
    margin-left: 0;
  }
  .proposition .marginTop {
    margin-top: 4rem;
  }
  .proposition .titile {
    font-size: 26px;
  }

  /* .proposition .divider {
    top: -12.5%;
    left: 30%;
    z-index: 11;
  } */
  .proposition .featuresCard {
    width: 50%;
    background-color: #f9f9f9;
    padding-top: 25px;
    padding-inline:0px
    
  }

  .proposition .benefitsCard {
    width: 50%;
    background-color: #f2f2f2;
    padding-top: 25px;
    padding-inline:0px;
    
  }

  .proposition .featuresCard h5{
    font-size: 16px;
    color: #585858;
    margin-top: 30px;
  }

  .proposition .benefitsCard h5{
    font-size: 16px;
    color: #585858;
    margin-top: 30px;
  }
  
  .underline:after{
    border-bottom: 2px solid #585858;
  }
  .tl-item{
    font-size: 12px;
  }
}
@media screen and (min-width: 280px) and (max-width: 320px) {
  .proposition .divider {
    top: -10.5%;
    left: 27%;
}
}
@media screen and (min-width: 321px) and (max-width: 376px) {
  .proposition .divider {
    top: -12.5%;
    left: 30%;
    z-index: 11;
}
}
@media screen and (min-width: 377px) and (max-width: 426px) {

  .proposition .divider {
    top: -14.5%;
    left: 32.5%;
    z-index: 11;
  }
}

@media screen and (min-width: 425px) and (max-width: 427px) {

  .proposition .divider {
    top: -14.5%;
    left: 33%;
    z-index: 11;
  }
}

@media screen and (min-width: 428px) and (max-width: 767px) {

  .proposition .divider {
    top: -13.5%;
    left: 32.5%;
  }
}



@media screen and (min-width: 768px) and (max-width: 1023px) {
 
  .featuresCard .tl-item{
    margin-right: 20%;
  }
  .benefitsCard .tl-item{
    margin-left: 10%;
  }
  .proposition .marginTop {
    margin-top: 4rem;
  }
  .proposition .titile {
    font-size: 35px;
  }
  .proposition .divider {
    top: -14%;
    left: 38.5%;
    
  }
  .proposition .featuresCard {
    background-color: #f9f9f9;
    padding: 30px 12px 12px;
   width: 50%;
  }

  .proposition .benefitsCard {
    background-color: #f2f2f2;
    padding: 30px 12px 12px;
    width: 50%;
  }

}

@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .proposition .divider {
    left: 41.5%;
}
}

@media screen and (min-width: 1280px) and (max-width: 1367px) {

  .proposition .divider {
    top: 35%;
    left: 43%;
}
}
