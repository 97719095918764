@font-face {
  font-family: Montserrat-Bold;
  src: url("../../../fonts/Montserrat-Bold.ttf");
}

@font-face {
  font-family: Montserrat-Light;
  src: url("../../../fonts/Montserrat-Light.ttf");
}

.formsuccessmsg .modal-dialog .modal-content {
  height: 19.125rem;
}

.formsuccessmsg .modal-header{
  border-bottom: none;
}

.formsuccessmsg .modal-dialog .modal-content .modal-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.formsuccessmsg .imgDiv {
  width: 100%;
}

.formsuccessmsg .imgDiv img {
  width: 74px;
}

.formsuccessmsg h1 {
  font-family: Montserrat-Bold;
  font-size: 1.8rem;
  color: #30be69;
  margin-top: 10px;
}

.formsuccessmsg p {
  font-family: Montserrat-Light;
  font-size: 12px;
  color: #666666;
}

@media screen and (min-width: 280px) and (max-width: 767px) {
  .formsuccessmsg h1 {
    font-size: 1.2rem;
  }
  .formsuccessmsg p {
    font-size: 0.688rem;
  }
}
