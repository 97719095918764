@font-face {
  font-family: title;
  src: url("../../../fonts/Montserrat-ExtraBold.ttf");
}

@font-face {
  font-family: desc;
  src: url("../../../fonts/Montserrat-ExtraLight.ttf");
}

.business-features {
  background-color: #020c53;
  padding-top: 40px;
  padding-bottom: 40px;
}

.business-features .leftPhoneImg {
  width: 350px;
}

.business-features .business-features-underline {
  color: #ffca3d;
  display: table;
  font-size: 48px;
  font-family: title;
}

.business-features .business-features-underline:after {
  border-bottom: 2px solid #ffca3d;
  content: "";
  display: block;
  width: 85px;
  padding: 5px;
}

.business-features .card {
  max-width: 23rem;
  min-width: 23rem;
  min-height: 12rem;
  margin: 10px;
  margin-left:6px;
}
.business-features .card .card-body {
  color: #020c53;
}

.business-features .card .card-body .card-title {
  font-family:title;
  font-size: 16px;
  margin-top: 10px;
}

.business-features .card .imgdiv {
  width: 64px;
  height: 64px;
  overflow: hidden;
}

@media screen and (min-width: 280px) and (max-width: 767px) {
  .business-features{
    padding-top: 25px;
  }
  .business-features .business-features-underline {
    margin-left: auto;
    margin-right: auto;
    font-size: 26px;
  }
  .business-features .business-features-underline:after {
    margin-left: auto;
    margin-right: auto;
  }

  .business-features .card {
    width: 170px;
    min-width: auto;
    min-height: 11rem;
    max-height: 11rem;
    margin: 5px;
    margin-bottom: 7px;
  }

  .business-features .card .imgdiv {
    width: 40px;
    height: 40px;
    overflow: hidden;
  }

  .business-features .card .card-body .card-title {
    font-size: 14px;
  }

  .business-features .card .card-body .card-text {
    font-size: 12px;
    line-height: 13px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .business-features .business-features-underline {
    margin-left: auto;
    margin-right: auto;
  }
  .business-features .business-features-underline:after {
    margin-left: auto;
    margin-right: auto;
  }

  .business-features .card {
    max-width: 18rem;
        min-width: 18rem;
        min-height: 12rem;
        margin: 9px;
        margin-left:8px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
    .business-features .card {
        max-width: 18rem;
        min-width: 18rem;
        min-height: 12rem;
        margin: 10px;
        margin-left:8px;
      }
}
