@font-face {
  font-family: title;
  src: url("../../../fonts/Montserrat-ExtraBold.ttf");
}

@font-face {
  font-family: desc;
  src: url("../../../fonts/Montserrat-ExtraLight.ttf");
}

.business-form{
    padding-top: 30px;
    padding-bottom: 30px;   
}

.business-form .business-form-underline {
    color: #020c53;
    display: table;
    margin-left: auto;
    margin-right: auto;
    font-family: title;
    font-size: 48px;
  }
  
  .business-form .business-form-underline:after {
    border-bottom: 2px solid #FFCA3D;
    content: "";
    display: block;
    width: 85px;
    padding: 3px;
    margin-left: auto;
    margin-right: auto;
  }

  .business-form .form-control.is-invalid{
    border: 2px solid #dc3545 !important;
    box-shadow: none;
    background-image: none;
  }
  
  .business-form .form-control:focus{
    box-shadow: none;
  }

  .business-form .form-control.is-invalid:focus{
    box-shadow: none;
  }

  .business-form .form-select{
    background-color: #ECECEC;
  }
  .business-form .form-control{
    background-color: #ECECEC;
  }
  .business-form .form-label {
    color: #2d2929;
    font-weight: 800;
  font-family: labelFont;
  font-size: 18px;
  }
  
  .business-form .formsWrappers {
    width: 28rem;
  }
  .business-form .formsWrappers button{
    padding: 12px !important;
    background-color: #D94E0C;
    box-shadow: 6px 7px 18px rgba(217, 78, 12, 0.35); 
  }
 
  .business-form .formsWrappers .form-control,.form-select{
    height: 50px;
  }

  .business-form .formsWrappers button:hover{

    background: #020C53;
    box-shadow: 6px 7px 18px rgba(2, 12, 83, 0.15);
  }

  .business-form .spinner-border{
    border: 0.25em solid rgba(255, 255, 255, 0.3);
    border-top-color: #FFCA3D;
    border-right-color: #FFCA3D;
    vertical-align: -0.3em;
    height: 20px;
    width: 20px;
  }

  .business-form .invalid-feedback{
    font-style: italic;
  }

@media screen and (min-width: 280px) and (max-width: 767px) {
    .business-form .formsWrappers {
        width: 100%;
      }

      .business-form .business-form-underline {
        font-size: 25px;
      }
      .business-form .subtitle {
        font-size: 13px;
      }
}
