.account-delete-form input{
    height: 50px !important;
}

.account-delete-form button:hover {
    background: #020C53;
    box-shadow: 6px 7px 18px rgba(2, 12, 83, 0.15);
}

.account-delete-form button{
    background-color: #D94E0C;
    box-shadow: 6px 7px 18px rgba(217, 78, 12, 0.35);
}
.warning-message{
    font-style: italic !important;
    font-weight: bold;
}