@font-face {
  font-family: title;
  src: url("../../../fonts/Montserrat-Bold.ttf");
}

@font-face {
  font-family: subtitle;
  src: url("../../../fonts/Montserrat-Light.ttf");
}

@font-face{
  font-family: labelFont;
  src: url("../../../fonts/Montserrat-ExtraBold.ttf");
}

.ad-joinform {
  background-color: #ffca3d;
  padding-top: 30px;
  padding-bottom: 30px;
}

.ad-joinform .title {
  width: 46rem;
  color: #020c53;
  font-family: title;
}

.ad-joinform .title h6 {
  font-family: subtitle;
  font-weight: 600;
}

.ad-joinform .ad-joinform-underline {
  display: table;
}

.ad-joinform .ad-joinform-underline:after {
  border-bottom: 2px solid #020c53;
  content: "";
  display: block;
  width: 50px;
  padding: 3px;
  margin-left: auto;
  margin-right: auto;
}

.ad-joinform .form-control.is-invalid{
  border: 2px solid #dc3545 !important;
  box-shadow: none;
  background-image: none;
}

.ad-joinform .form-control:focus{
  box-shadow: none;
}

.ad-joinform .form-control.is-invalid:focus{
  box-shadow: none;
}

.ad-joinform .form-label {
  color: #2d2929;
  font-weight: 800;
  font-family: labelFont;
  font-size: 18px;
}

.ad-joinform .form-control,.form-select{
  height: 50px;
}

.ad-joinform .formsWrappers {
  width: 46rem;
}

.ad-joinform .formsWrappers button{
  background-color: #D94E0C;
  padding: 12px !important;
  box-shadow: 6px 7px 18px rgba(217, 78, 12, 0.35);
}

.ad-joinform .formsWrappers button:hover{
  background: #020C53;
box-shadow: 6px 7px 18px rgba(2, 12, 83, 0.15);
}

.ad-joinform .grecaptcha-badge{
  min-width: 300px !important;
  box-shadow:none !important;
}

.ad-joinform #google_recaptcha iframe{
  width: 100% !important;
}

.ad-joinform .spinner-border{
  border: 0.25em solid rgba(255, 255, 255, 0.3);
  border-top-color: #FFCA3D;
  border-right-color: #FFCA3D;
  vertical-align: -0.3em;
  height: 20px;
  width: 20px;
}

.ad-joinform .invalid-feedback{
  font-style: italic;
}
@media screen and (min-width: 280px) and (max-width: 1023px) {
  
  .ad-joinform .title h6{
    font-family: subtitle;
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
  }
  .ad-joinform .title {
    width: 100%;
  }
  .ad-joinform .formsWrappers {
    width: 100%;
  }
}


@media screen and (min-width: 280px) and (max-width: 767px) {
  .ad-joinform .title h6{
    font-family: subtitle;
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
  }
  .ad-joinform .title {
    width: 100%;
  }
  .ad-joinform .formsWrappers {
    width: 100%;
  }
  .ad-joinform .grecaptcha-badge{
    min-width: 100% !important;
  }
}
