*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face{
  font-family: basefont;
  src: url("../src/fonts/Montserrat-Regular.ttf");
}

body {
  font-family: basefont !important;
}