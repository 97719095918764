@font-face {
  font-family: Montserrat-Bold;
  src: url("../../../fonts/Montserrat-Bold.ttf");
}

@font-face {
  font-family: Montserrat-Regular;
  src: url("../../../fonts/Montserrat-Regular.ttf");
}

.partner-banner {
  background: linear-gradient(
      72.33deg,
      rgba(1, 8, 58, 0.7) 100%,
      rgba(2, 12, 83, 0) 100%
    ),
    url("../../../img/partner/banner/parnerbanner.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  min-height: 360px;
}

.partner-banner .partner-banner-heading {
  font-family: Montserrat-Bold;
  font-size: 48px;
}

.partner-banner .breadcrumb-item{
    font-family: Montserrat-Regular;
    font-size: 14px;
   }

.partner-banner .breadcrumb-item.active {
  color: #ffca3d;
  font-weight: 500;
  
  margin-top: auto;
}
.partner-banner .breadcrumb .breadcrumb-item a {
  text-decoration: none;
  color: white;
 
  font-weight: 500;
}

.partner-banner .breadcrumb .breadcrumb-item a:hover {
  text-decoration: none;
  color: #ffca3d;
}
.partner-banner .breadcrumb-item + .breadcrumb-item::before {
  color:#fff; 
}

@media screen and (min-width: 280px) and (max-width: 321px) {
  .partner-banner {
    background: linear-gradient(
        72.33deg,
        rgba(1, 8, 58, 0.48) 100%,
        rgba(2, 12, 83, 0) 100%
      ),
      url("../../../img/partner/banner/responsivebanner.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 250px;
  }

  .partner-banner .breadcrumb{
    display: flex;
    justify-content: center;
  }
  .partner-banner .partner-banner-heading {
    font-size: 26px;
  }
  .partner-banner .breadcrumb-item{
    font-size: 11px;
   }

   .partner-banner .breadcrumb-item+.breadcrumb-item::before{
    padding-right: 0.3rem;
   }

   .joining-us .joining-us-underline{
    font-size: 25px;
   }
   .joining-us .subtitle{
    font-size:14px;
   }
}

@media screen and (min-width: 322px) and (max-width: 767px) {
  .partner-banner {
    background: linear-gradient(
        72.33deg,
        rgba(1, 8, 58, 0.48) 100%,
        rgba(2, 12, 83, 0) 100%
      ),
      url("../../../img/partner/banner/responsivebanner.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 250px;
  }
  .partner-banner .partner-banner-heading {
    font-size: 26px;
  }
  .partner-banner .breadcrumb-item{
    font-size: 11px;
   }

   .joining-us .joining-us-underline{
    font-size: 25px;
   }
   .joining-us .subtitle{
    font-size:14px;
   }
}