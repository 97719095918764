
@font-face{
  font-family: boldTitle;
  src: url("../../fonts/Montserrat-ExtraBold.ttf");
}

.card.bannerCard {
  max-height: 170px;
  max-width: 180px;
  min-height: 170px;
  min-width: 180px;
  margin-right:5px;
  overflow: hidden;
  border-radius: 12px;
}

.bannerCardWrapper .bannerCard .card-body{
  padding-top: 5px;
  padding-inline: 2px;
}
.card.bannerCard .cardimg {
  width: 50px;
  height: 60px;
  overflow: hidden;
}
.bannerCard .card-title img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.bannerCard .card-subtitle {
  font-family: boldTitle;
  color: #020c53;
  font-size: 15px;
  margin-top: 8px;
}

.bannerCard .card-text {
  font-size: 11px;
  color: #5f6695;
  padding: 4px;
  padding-top: 0px;
}

.bannerCardWrapper {
  background-color: #020c53;
  min-height: 370px;
  padding-top: 120px;
  padding-bottom: 40px;
}

.card.bannerCard:hover {
  background-color: #ffca3d;
  cursor: pointer;
}

@media screen and (min-width: 280px) and (max-width: 767px) {
  .bannerCardWrapper{
    padding-bottom:30px;
  }
  .card.bannerCard {
    width: 100%;
    min-width: auto;
    min-height: auto;
    margin-bottom: 15px;
  }
  .card.bannerCard .cardimg {
    width: 40px;
    height: 40px;
    overflow: hidden;
  }
  .bannerCard .card-text {
    font-size: 9px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

  .card.bannerCard{
    max-height: 100%;
    width: 100%;
    margin-right:5px;
  }
}
