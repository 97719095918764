@font-face{
  font-family: title;
  src: url("../../../fonts/Montserrat-ExtraBold.ttf");
}


.b2playVideoSection {
  background: linear-gradient(180deg, #020c53 0%, #010836 100%);
  padding-top: 30px;

}

.b2playVideoSection .b2playVideoSection-underline{
  display: table;
  margin-inline:auto;
  font-family: title;
  font-size: 48px;
}

.b2playVideoSection .b2playVideoSection-underline:after {
  border-bottom: 2px solid #FFCA3D;
  content: '';
  display: block;
  width: 85px;
  padding: 3px;
}

.b2playVideoSection .titile {
  font-weight: 800;
  font-size: 48px;
  line-height: 48px;
  color: #ffca3d;
}

/* .b2playVideoSection .horizontalLine {
  width: 100px; */
  /* margin: 0 auto; */
  /* border: 1px solid #ffca3d;
} */

.b2playVideoSection .card {
  width: 35rem;
}

.underline {
  display: table;
  margin-left: auto;
  margin-right: auto;
}

.underline:after {
  border-bottom: 3px solid #ffca3d;
  content: '';
  display: block;
  width: 30%;
}

.b2playVideoSection .rh5v-DefaultPlayer_component .rh5v-Overlay_component .rh5v-Overlay_inner{
  width: 76px;
  height: 76px;
  border-radius: 50%;
  background-color:transparent;
  border: 2px solid #FFFFFF;
}

.b2playVideoSection .rh5v-DefaultPlayer_component .rh5v-Overlay_component .rh5v-Overlay_inner .rh5v-Overlay_icon{
  width: 59px;
  height: 60px;
  position: absolute;
  top: 50%;
  right: 10%;
  left: 40%;
  transform: translateY(-50%);
}

@media screen and (min-width: 280px) and (max-width: 767px) {

  .b2playVideoSection .b2playVideoSection-underline{
    margin-inline:unset;
    font-size: 28px;
  }
  .b2playVideoSection{
    padding-top: 20px;
    padding-bottom: 0;
  }
  .underline{
    display: table;
    margin-inline: 0;
  }
  .b2playVideoSection .rh5v-DefaultPlayer_component .rh5v-Overlay_component .rh5v-Overlay_inner{
    width: 62px;
    height: 62px;
  }
  
  .b2playVideoSection .rh5v-DefaultPlayer_component .rh5v-Overlay_component .rh5v-Overlay_inner .rh5v-Overlay_icon{
    width: 55px;
    height: 55px;
    position: absolute;
    top: 50%;
    right: 0%;
    transform: translateY(-50%);
  }
  .b2playVideoSection .card {
    width: 100%;
  }
  .b2playVideoSection .titile {
    font-size: 26px;
    line-height: 20px;
  }
  .b2playVideoSection .card .card-body {
    font-size: 14px;
  }

}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b2playVideoSection .b2playVideoSection-underline{
    margin-inline:unset;
  }
  .b2playVideoSection{
    padding-top: 20px;
    padding-bottom: 0;
  }
  .underline{
    display: table;
    margin-inline: 0;
  }
}
