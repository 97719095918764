

@font-face{
  font-family: span;
  src: url("../../../fonts/Montserrat-ExtraBold.ttf");
}

@font-face{
  font-family: desc;
  src: url("../../../fonts/Montserrat-Light.ttf");
}

.b2PlayMiddleSection {
  background-image: url(../../../img/home/b2PlayMiddleSection/Rectangle.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 150px;
  padding-bottom: 80px;
}

.b2PlayMiddleSection .b2PlayMiddleSectionCard {
  min-width: 30rem;
}

.b2PlayMiddleSection .leftb2PlayMiddleSectionImgWrapper {
  width: 25rem;
  overflow: hidden;
}

.b2PlayMiddleSection .leftb2PlayMiddleSectionImgWrapper .logoImg img {
  width: 270px;
}

.b2PlayMiddleSection .leftb2PlayMiddleSectionImgWrapper .peopleImg img {
  width: 400px;
}

.b2PlayMiddleSection .b2PlayMiddleSectionCard .b2PlayMiddleSectionCardDesc {
  font-family: desc;
  font-weight:500;
}

.b2PlayMiddleSection .b2PlayMiddleSectionCard .b2PlayMiddleSectionCardDesc span {
  font-family: span;
  font-weight:500;
}

@media screen and (min-width: 280px) and (max-width: 767px) {
  .b2PlayMiddleSection {
    background-image: url(../../../img/home/b2PlayMiddleSection/responsiveMobile.png);
    padding-top: 50px;
  }
  .b2PlayMiddleSection .b2PlayMiddleSectionCard {
    min-width: 100%;
  }

  .b2PlayMiddleSection .leftb2PlayMiddleSectionImgWrapper {
    text-align: center;
    width: 100%;
    overflow: hidden;
  }
  .b2PlayMiddleSection .leftb2PlayMiddleSectionImgWrapper .logoImg img {
    width: 70%;
  }

  .b2PlayMiddleSection .leftb2PlayMiddleSectionImgWrapper .peopleImg img {
    width: 100%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b2PlayMiddleSection .b2PlayMiddleSectionCard {
    width: 30rem;
  }
  .b2PlayMiddleSection {
    padding-top: 60px;
    padding-bottom: 50px;
  }
}
