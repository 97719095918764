@font-face{
  font-family: button-text;
  src: url("../../fonts/Montserrat-ExtraBold.ttf");
}
@font-face{
  font-family: hoverText;
  src: url("../../fonts/Montserrat-Bold.ttf");
}

.navbar-custom-bgcolor {
  background: #020c53;
  border-radius: 0px 0px 24px 24px;
  height: 125px;
}

.navbar-nav .registerSearchButton {
  background-color: #ffffff;
  border-radius: 40px;
  color: #020c53;
  padding: 7px;
  font-family: button-text;
  padding-inline: 20px;
}

.navbar-nav .registerSearchButton:hover {
  background-color: #ffca3d;
  border-radius: 40px;
}

.navbar .navBarSocialWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.navbar .navBarSociaMediaWrapper{
    display: flex;
    width: 30%;
    
}
.navbar .navBarSociaMediaIcon{
  width: 12px;
  overflow: hidden;
}

.navbar .navBarSociaMediaIcon img{
    width: 100%;
    height: 100%;
    object-fit: cover;  
}
.navbar-custom-bgcolor.navbar-expand-lg {
    align-items: baseline;
}

.navbar-custom-bgcolor .container {
  margin-top: 10px;
}
.searchNavigation {
  top: 60%;
}

.searchNavigation > .container {
  background: #ffca3d;
  height: 75px;
  border-radius: 8px;
  color: #020c53;
  
  font-weight: 500;
  padding-left: 25px ;
 
}

.searchNavigation > .container #useapp:hover,
#advr:hover,
#buss:hover,
#con:hover {
  font-family: hoverText;
  cursor: pointer;
}


.navbar .dropdown .dropdown-menu {
  border-radius: 0px;
  padding: 0px;
}

.navbar .dropdown .dropdown-toggle::after {
  color: #fff;
  margin-left: 5px;
  display: none;
}


.navbar .dropdown .dropdown-menu .dropdown-item {
  padding-left: 5px;
  color: #020c53;
  font-weight: 600;
}

.navbar .dropdown .dropdown-menu .dropdown-item:hover {
  background-color: #c6c6c6;
}

.navbar .dropdown .dropdown-menu > .dropdown-item {
  border-bottom: 1px solid;
}

.navbar .dropdown .dropdown-menu > .dropdown-item ~ .dropdown-item {
  border: none;
}

.navbar .dropdown .languageFlag {
  display: inline-block;
  width: 25px;
  margin-right: 5px;
}

.navbar .dropdown .languageFlag img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

ul.navbar-social-network {
  list-style: none;
  display: inline;
  margin-left: 0 !important;
  padding: 0;
}

ul.navbar-social-network li {
  display: inline;
  /* margin: 0 5px; */
}

.navbar-social-network a.icoFacebook:hover i,
.navbar-social-network a.icoLinkedin:hover i {
  color: #fff;
}

.navbar-social-network a.socialIcon:hover,
.socialHoverClass {
  color: #44bcdd;
}


.navbar-social-circle li a {
  display: inline-block;
  position: relative;
  margin: 0 auto 0 auto;
  text-align: center;
  width: 25px;
  height: 30px;
  font-size: 15px;
}

.navbar-social-circle li i {
  margin: 0;
  line-height: 30px;
  text-align: center;
}


.navbar-social-network a img{
    width: 12px;
}


.navbar-custom-bgcolor.navbar .hiding{
  display: none !important;

}

.navbar-custom-bgcolor.navbar .showMobile{
  display: none !important;
  z-index: 1;
}
.navbar-custom-bgcolor .mobileMenu{
  width: 270px;
  background-color: #FFCA3D;
  margin-top: 10px;
  box-shadow: -4px -2px 7px rgba(0, 0, 0, 0.28);
  border-radius: 8px;
  padding: 18px;
  display: flex;
  flex-direction: column;
 
}

.navbar-custom-bgcolor .mobileMenu .mobileMenuArrowImage{
  position: absolute;
  right: 5px;
  top: -10px;
}

.navbar-custom-bgcolor.navbar .hiding .showMobile h3{
  font-family:button-text;
}

.nav-link .navbar-social-network .fa-facebook-f:hover,.fa-instagram:hover,.fa-tiktok:hover,.fa-linkedin-in:hover{
  color: #fff;
}

@media screen and (min-width: 280px) and (max-width: 767px) {
  .navbar-custom-bgcolor{
    height: 100px;
  }
  .navbar-custom-bgcolor.navbar .hiding.show{
    display: block !important;
  }

  .navbar-custom-bgcolor.navbar .showMobile{
    display: block !important;
  }

}
@media screen and (min-width: 280px) and (max-width: 1023px) {
 
  .navbar-nav .registerSearchButton{
    padding: 12px;
    font-size: 14px;
  }
  .navbar-custom-bgcolor.navbar .hiding.show{
    display: block !important;
  }

  .navbar-custom-bgcolor.navbar .showMobile{
    display: block !important;
  }
}
