@font-face {
  font-family: title;
  src: url("../../../fonts/Montserrat-ExtraBold.ttf");
}

@font-face {
  font-family: desc;
  src: url("../../../fonts/Montserrat-ExtraLight.ttf");
}



.business-solution {
  background: url(".././../../img/business/solution/solutionbg.png");
  background-color: #020C53;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  padding-top: 30px;
  min-height: 600px;
}

.business-solution .leftContent {
  width: 470px;
  font-family: desc;
  font-size: 16px;
   font-weight: 600;
}

.business-solution .leftContent .business-solution-underline {
  display: table;
  font-family: title;
  font-size: 48px;
}

.business-solution .leftContent .business-solution-underline:after {
  border-bottom: 2px solid #ffca3d;
  content: "";
  display: block;
  width: 85px;
  padding-bottom: 5px;
}

@media screen and (min-width: 280px) and (max-width: 767px) {
  .business-solution .leftContent .business-solution-underline {
    font-size: 26px;

}
.business-solution .leftContent {
  font-size: 14px;
  font-weight: 500;
}
    .business-solution {
        background: url("../../../img/business/solution/msolutionbg.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        padding-top: 30px;
        padding-bottom: 30px;
        min-height: 600px;
      }
      
}


@media screen and (min-width: 768px) and (max-width: 1023px) {
   
}