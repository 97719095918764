@font-face {
  font-family: title;
  src: url("../../../fonts/Montserrat-ExtraBold.ttf");
}

@font-face {
    font-family: Montserrat-Regular;
    src: url("../../../fonts/Montserrat-Regular.ttf");
  }

.business-bannervideo {
  height: 555px;

  background: linear-gradient(
      72.33deg,
      rgba(1, 8, 58, 0.8) -0.1%,
      rgba(2, 12, 83, 0.8) 42.51%
    ),
    url("../../../img/business/bannerVideo/businessBannerBg.png");
  background-size: cover;
  background-position: bottom;
}

.business-bannervideo h1{
    font-family: title;
    font-size: 48px;
    margin-top: 30px;
}

.business-bannervideo .breadcrumb-item{
    font-family: Montserrat-Regular;
   }

   .business-bannervideo .breadcrumb-item + .breadcrumb-item::before {
    color:#fff; 
  }
.business-bannervideo .breadcrumb-item.active {
  color: #ffca3d;
  font-weight: 500;
  font-size: 14px;
  margin-top: auto;
}

.business-bannervideo .breadcrumb .breadcrumb-item a {
  text-decoration: none;
  color: white;
  font-size: 14px;
  font-weight: 500;
}

.business-bannervideo .breadcrumb .breadcrumb-item a:hover {
  text-decoration: none;
  color: #ffca3d;
}

@media screen and (min-width: 280px) and (max-width: 320px) {
  .business-bannervideo h1{
    font-size: 26px;
   
  }
  .business-bannervideo {
    height: 300px;
  }
}

@media screen and (min-width: 321px) and (max-width: 767px) {
  .business-bannervideo h1{
    font-size: 26px;
  }
  .business-bannervideo {
    height: 300px;
  }
  .business-stripaccount .videoWarpper {
    height: 40%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .business-bannervideo h1{
    font-size: 35px;
  }
  .business-bannervideo {
    height: 475px;
  }
}
