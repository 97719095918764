@font-face {
  font-family: Montserrat-ExtraBold;
  src: url("../../../../fonts/Montserrat-ExtraBold.ttf");
}

@font-face {
  font-family: Montserrat-Regular;
  src: url("../../../../fonts/Montserrat-Regular.ttf");
}

.joining-us {
  background-color: #020c53;
}

.joining-us .joining-us-underline {
  display: table;
  color: #ffca3d;
  font-family: Montserrat-ExtraBold;
  font-size: 38px;
}

.joining-us .joining-us-underline:after {
  border-bottom: 2px solid #ffca3d;
  content: "";
  display: block;
  width: 85px;
  padding: 5px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
}

.joining-us .subtitle {
  font-family: Montserrat-Regular;
}

@media screen and (min-width: 280px) and (max-width: 767px) {
  .joining-us .joining-us-underline {
    font-size: 25px;
  }
  .joining-us .subtitle {
    font-size: 14px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .joining-us .joining-us-underline {
    font-size: 35px;
  }
}
