.dropzone-section .thumbsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
}

.dropzone-section .removeIconCotainer {
  width: 28px;
  height: 28px;
  background: rgba(217, 217, 217, 0.5);
  backdrop-filter: blur(2px);
  position: absolute;
  top: 38%;
  left: 36%;
  border-radius: 50%;
}

.dropzone-section .dropContainer {
  color: #fff;
  display: flex;
  min-height: 124px;
  background: rgba(255, 255, 255, 0.2);
  border: 2px dashed #ffffff;
  border-radius: 8px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dropzone-section .validate{
    border-color: #dc3545;
}

.dropzone-section .colorRed {
  color: #d94e0c;
}

.dropzone-section .thumb {
  display: inline-flex;
  margin-bottom: 8px;
  margin-right: 5px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
  /*  border: "1px solid #eaeaea"; */
}

.dropzone-section .thumbInner {
  display: flex;
  min-width: 0;
  overflow: hidden;
  border-radius: 4px;
  position: relative;
}

.dropzone-section .img {
  display: block;
  width: auto;
  height: 100%;
}

.dropzone-section .removeIconCotainerImage {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 30%;
  margin: auto;
}


.dropzone-section .removeIconCotainer:hover{
    cursor: pointer;
}