@font-face {
    font-family: title;
    src: url("../../../fonts/Montserrat-ExtraBold.ttf");
  }

  @font-face {
    font-family: desc;
    src: url("../../../fonts/Montserrat-Regular.ttf");
  }

.business-facts{
    background-color: #020C53; 
    padding-bottom: 30px;
}

.business-facts .rightImg{
    width: 400px;
}

.business-facts .leftContent{
    width: 460px;
    font-family: desc;
    font-weight:600;
    font-size: 16px;
    font-style: normal;

}

.business-facts .business-facts-underline {
    color: #FFCA3D;
    display: table;
    margin-left:auto;
    margin-right:auto;
    font-family: title;
    font-size: 48px;
  }
  
 .business-facts .business-facts-underline:after {
    border-bottom: 1px solid #FFCA3D;
    content: '';
    display: block;
    width: 85px;
    padding: 3px;
  }

  @media screen and (min-width: 280px) and (max-width: 767px) {
    .business-facts{
        padding-bottom: 0;
    }
    .business-facts .business-facts-underline {
        font-family: title;
        font-size: 26px;
    }
    .business-facts .leftContent{
        font-weight:500;
        font-size: 14px;
        font-style: normal;
    
    }
  }
  

