@font-face {
  font-family: Montserrat-SemiBold;
  src: url("../../../../fonts/Montserrat-SemiBold.ttf");
}

.partner-forms .form-tab .nav-tabs .nav-link {
  font-family: "Montserrat-SemiBold";
  border: 0;
  white-space: nowrap;
  font-size: 18px;
}

.partner-forms .form-tab .nav-tabs {
  border-bottom: none;
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
}

.partner-forms .nav-tabs .nav-link.active {
  background-color: transparent;
  border-bottom: 2px solid #ffca3d;
  color: #ffca3d;
  font-weight: 500;
  padding-left: 4px;
  padding-right: 9px;
}
.partner-forms .nav-link {
  color: #fff;
  font-weight: 500;
}

.partner-forms .nav-link:hover {
  color: #ffca3d;
}
