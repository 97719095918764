@font-face{
  font-family: title;
  src: url("../../fonts/Montserrat-ExtraBold.ttf");
}

.discoverNewSide {
  padding-top: 75px;
  min-height: 490px;
}
.discoverNewSideContent {
  font-family: title;
  font-size: 60px;
  line-height: 60px;
  color: #ffffff;

}
.discoverNewSideContent span {
  color: #ffca3d;
}

.discoverNewSideContent .appStore img {
  margin-top: 30px;
  margin-right: 20px;
  width: 175px;
}
.discoverNewSide .twophone img {
  width: 430px;
}
.discoverNewSide .twophone {
  top: -25%;
  left: 0px;
}

@media screen and (min-width: 280px) and (max-width: 374px) {
  .discoverNewSide {
    padding-top: 40px;
  }
  .discoverNewSideContent {
    font-size: 30px;
    line-height: 30px;
  }
  .discoverNewSideContent .appStore img {
    margin-top: 30px;
    margin-right: 20px;
    width: 42%;
  }
  .discoverNewSide .twophone img {
    text-align: center;
    width: 100%;
  }
  .discoverNewSide .twophone {
    top: 0px;
    left: 0px;
  }
}
@media screen and (min-width: 375px) and (max-width: 426px) {
  .discoverNewSide {
    padding-top: 40px;
  }
  .discoverNewSideContent {
    font-size: 30px;
    line-height: 30px;
  }

  .discoverNewSideContent .appStore img {
    margin-top: 30px;
    margin-right: 20px;
    width: 155px;
  }
  .discoverNewSide .twophone img {
    width: 85%;
  }
  .discoverNewSide .twophone {
    left: 0px;
    text-align: center;
  }
}
@media screen and (min-width: 427px) and (max-width: 767px) {
  .discoverNewSide .twophone {
    top: -25px;
    left: 0px;
  }
  .discoverNewSide {
    padding-top: 40px;
  }
  .discoverNewSideContent {
    font-size: 30px;
    line-height: 30px;
  }
  .discoverNewSideContent .appStore img {
    margin-top: 30px;
    margin-right: 20px;
    width: 155px;
  }
  .discoverNewSide .twophone {
    text-align: center;
    width: 100%;
  }
  .discoverNewSide .twophone img {
    width: 85%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .discoverNewSide {
    padding-top: 0;
    text-align: center;
  }
  .discoverNewSide .discoverNewSideContent {
    margin-top: 5% !important;
  }
  .discoverNewSideContent .appStore img {
    width: 148px;
  }
  .discoverNewSideContent {
    font-size: 44px;
    line-height: 44px;
  }
  .discoverNewSide .twophone img {
    width: 45%;
  }
  .discoverNewSide .twophone {
    top: 15%;
    left: 0px;
    text-align: center;
  }
}
