@font-face {
  font-family: pageTitle;
  src: url("../../../fonts/Montserrat-ExtraBold.ttf");
}

@font-face {
    font-family: breadcum;
    src: url("../../../fonts/Montserrat-Medium.ttf");
  }

  @font-face {
    font-family: Montserrat-Regular;
    src: url("../../../fonts/Montserrat-Regular.ttf");
  }
.ad-banner {
  background:linear-gradient(72.33deg, rgba(1, 8, 58, 0.7) 100%, rgba(2, 12, 83, 0) 100%),
    url("../../../img//advertising/banner/adbanner.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  min-height: 300px;
}

.ad-banner h1 {
 font-family: pageTitle;
 font-size: 48px;
}

.ad-banner .breadcrumb-item{
 font-family: Montserrat-Regular;
}

.ad-banner .breadcrumb-item + .breadcrumb-item::before {
  color:#fff; 
}

.ad-banner .breadcrumb-item.active {
  color: #ffca3d;
  font-weight: 500;
  font-size: 14px;
  margin-top: auto;
 
}

.ad-banner .breadcrumb .breadcrumb-item a {
  text-decoration: none;
  color: white;
  font-weight: 500;
}

.ad-banner .breadcrumb .breadcrumb-item a:hover {
  text-decoration: none;
  color: #ffca3d;
}


@media screen and (min-width: 280px) and (max-width: 767px) {
  .ad-banner {
    min-height: 250px;
  }
  .ad-banner h1 {
    font-size: 25px;
   }
   .ad-banner .breadcrumb .breadcrumb-item {
    font-size: 12px;
  }
}
