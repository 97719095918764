@font-face {
  font-family: title;
  src: url("../../../fonts/Montserrat-ExtraBold.ttf");
}

.business-stripaccount {
  background-color: #020c53;
  height: 470px;
}

.business-stripaccount .videoWarpper {
  width: 100%;
  height: 100%;
  position: relative;
}

.business-stripaccount .videoWarpper .videoCard {
  position: absolute;
  top: -100%;
  left: 20%;
  width: 750px;
  height: 425px;
}
.business-stripaccount .videoWarpper .videoCard iframe {
  height: 100%;
}
.business-stripaccount .strip-button {
  margin-top: 2.5rem;
  width: 30%;
  font-size: 19px;
  padding: 15px;
  font-family: title;
  background-color: #635BFF;
  box-shadow: 9px 7px 17px rgba(99, 91, 255, 0.29);
  border:none;
}

.business-stripaccount .strip-button:hover {
 background-color: #413ACE;
 border:none;
}

.business-stripaccount .rh5v-DefaultPlayer_component .rh5v-Overlay_component .rh5v-Overlay_inner{
  width: 76px;
  height: 76px;
  border-radius: 50%;
  background-color:transparent;
  border: 2px solid #FFFFFF;
}

.business-stripaccount .rh5v-DefaultPlayer_component .rh5v-Overlay_component .rh5v-Overlay_inner .rh5v-Overlay_icon{
  width: 59px;
  height: 60px;
  position: absolute;
  top: 50%;
  right: 10%;
  left: 40%;
  transform: translateY(-50%);
}

@media screen and (min-width: 280px) and (max-width: 320px) {
  .business-stripaccount .rh5v-DefaultPlayer_component .rh5v-Overlay_component .rh5v-Overlay_inner{
    width: 62px;
    height: 62px;
  }
  
  .business-stripaccount .rh5v-DefaultPlayer_component .rh5v-Overlay_component .rh5v-Overlay_inner .rh5v-Overlay_icon{
    width: 55px;
    height: 55px;
    position: absolute;
    top: 50%;
    right: 0%;
    transform: translateY(-50%);
  }
  .business-stripaccount {
    height: 325px;
  }
  .business-stripaccount .videoWarpper {
    height: 25%;
  }
  .business-stripaccount .videoWarpper .videoCard {
    width: 100%;
    left: 0;
    top: -80px;
    height: auto;
  }
  .business-stripaccount .videoWarpper .videoCard iframe {
    height: 40%;
  }

  .business-stripaccount .strip-button {
    width: 72%;
    font-size: 16px;
    padding: 15px;
  }
}

@media screen and (min-width: 321px) and (max-width: 376px) {
   .business-stripaccount .rh5v-DefaultPlayer_component .rh5v-Overlay_component .rh5v-Overlay_inner{
    width: 62px;
    height: 62px;
  }
  
  .business-stripaccount .rh5v-DefaultPlayer_component .rh5v-Overlay_component .rh5v-Overlay_inner .rh5v-Overlay_icon{
    width: 55px;
    height: 55px;
    position: absolute;
    top: 50%;
    right: 0%;
    transform: translateY(-50%);
  }
  .business-stripaccount {
    height: 325px;
  }
  .business-stripaccount .videoWarpper {
    height: 40%;
  }
  .business-stripaccount .videoWarpper .videoCard {
    width: 100%;
    left: 0;
    top: -80px;
    height: auto;
  }
  .business-stripaccount .videoWarpper .videoCard iframe {
    height: 45%;
  }
  .business-stripaccount .strip-button {
    width: 70%;
    font-size: 16px;
    padding: 15px;
  }
}
@media screen and (min-width: 377px) and (max-width: 767px) {
  .business-stripaccount .rh5v-DefaultPlayer_component .rh5v-Overlay_component .rh5v-Overlay_inner{
    width: 62px;
    height: 62px;
  }
  
  .business-stripaccount .rh5v-DefaultPlayer_component .rh5v-Overlay_component .rh5v-Overlay_inner .rh5v-Overlay_icon{
    width: 55px;
    height: 55px;
    position: absolute;
    top: 50%;
    right: 0%;
    transform: translateY(-50%);
  }
  .business-stripaccount {
    height: 370px;
  }
  .business-stripaccount .videoWarpper {
    height: 55%;
  }
  .business-stripaccount .videoWarpper .videoCard {
    width: 100%;
    left: 0;
    top: -80px;
    height: auto;
  }
  .business-stripaccount .videoWarpper .videoCard iframe {
    height: 50%;
  }
  .business-stripaccount .strip-button {
    width: 70%;
    font-size: 16px;
    padding: 15px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .business-stripaccount .videoWarpper .videoCard {
    width: 100%;
    left: 0%;
  }
  .business-stripaccount .videoWarpper .videoCard iframe {
    height: 95%;
  }
  .business-stripaccount .strip-button {
    margin-top: 2.5rem;
    width: 50%;
    font-size: 16px;
    padding: 15px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .business-stripaccount .videoWarpper .videoCard {
    left: 10%;
  }
  .business-stripaccount .videoWarpper .videoCard iframe {
    height: 100%;
  }
  .business-stripaccount .strip-button {
    margin-top: 2.5rem;
    width: 40%;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1439px) {
  .business-stripaccount .videoWarpper .videoCard {
    left: 15%;
  }
  .business-stripaccount .videoWarpper .videoCard iframe {
    height: 100%;
  }
}
