@font-face {
  font-family: title;
  src: url("../../../fonts/Montserrat-ExtraBold.ttf");
}


.business-mind {
  background-color: #020c53;
  min-height: 470px;
  padding-top: 35px;
  padding-bottom: 35px;
  
}

.business-mind .business-mind-underline {
  color: #ffca3d;
  display: table;
  margin-left: auto;
  margin-right: auto;
  font-family: title;
    font-size: 48px;
}

.business-mind .business-mind-underline:after {
  border-bottom: 2px solid #ffca3d;
  content: "";
  display: block;
  width: 85px;
  padding: 8px;
  margin-left: auto;
  margin-right: auto;
}

.accordion .accordion-item{
  border-radius: 5px;
  margin-top: 10px;
}
.accordion .accordion-item .accordion-header {
  font-family:title ;
}
.accordion .accordion-item .accordion-header .accordion-button{
  color: #020C53;
  font-weight: 800;
  background-color: #EAEAEA !important;
}

.accordion .accordion-item .accordion-header .accordion-button:not(.collapsed){
  color: #020C53;
  font-weight: 800;
  background-color: #EAEAEA !important;  
}

.accordion  .accordion-button:focus{
  border-color:black;
  box-shadow: none;
}
.accordion .accordion-item  .accordion-collapse .accordion-body{
  color:#676767;
  padding: 20px;
  text-align: left;
  font-weight: 500;
font-size: 16px;
line-height: 20px;
}

@media screen and (min-width: 280px) and (max-width: 767px) {
  .business-mind {
    padding-bottom: 30px;
  }
  .business-mind .business-mind-underline {
    font-size: 26px;
  }
  .accordion .accordion-item .accordion-header .accordion-button{
    font-size: 13px;
  }
  .accordion .accordion-item .accordion-collapse .accordion-body{
    font-size: 12px;
  }
}