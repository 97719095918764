@font-face {
  font-family: Montserrat-Bold;
  src: url("../../fonts/Montserrat-Bold.ttf");
}

@font-face {
  font-family: Montserrat-Light;
  src: url("../../fonts/Montserrat-Light.ttf");
}


/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.partner-forms .tabs-forms .form-label {
  font-family: Montserrat-Bold;
  color: #ffca3d;
  font-weight: 800;
  font-size: 18px;
}

.partner-forms .tabs-forms{
  max-width: 60rem;
}

.partner-forms .tabs-forms .form-control,
.form-select {
  height: 50px;
}

.partner-forms .tabs-forms .form-control:focus{
  box-shadow: none;
}

.partner-forms .tabs-forms .required:after {
  content: "*";
  color: #dc4444;
  padding-left: 5px;
}

.partner-forms .tabs-forms .form-check-input:checked[type="radio"] {
  background-image: url("../../img/partner/formstab/ellips.png");
  background-repeat: no-repeat;
  background-size: 65% 65%;
  background-position: 50% 50%;
}

.partner-forms .tabs-forms .form-check-input[type="radio"] {
  font-size: 19px;
}

.partner-forms .tabs-forms .form-check-input[type="checkbox"] {
  font-size: 17px;
}

.partner-forms .tabs-forms .form-check-input:checked[type="checkbox"] {

  background-image: url("../../img/partner/formstab/check.png");
  background-repeat: no-repeat;
  background-color: black !important;
}

.partner-forms .tabs-forms .form-check .form-check-label {
  font-family: Montserrat-Light;
  font-size: 12px;
  text-align: start;
}
.partner-forms .tabs-forms .form-check-input:focus {
  box-shadow: none;
}
.partner-forms .tabs-forms .form-check-input {
  border: 2px solid #ffffff;
  background-color: transparent;
}

.partner-forms .tabs-forms .form-check-input:checked {
  border: 1px solid #ffca3d;
}

.partner-forms .tabs-forms .form-select option {
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.39);
  border-radius: 4px;
}
.partner-forms .tabs-forms .radioButtonGroup {
  min-width: 230px;
  max-width: 400px;
  overflow: hidden;
  padding-left: 5px;
}

.partner-forms .tabs-forms .checkboxButtonGroup {
  max-width: 320px;
  overflow: hidden;
  padding-left: 5px;
}

.partner-forms .tabs-forms .marginTopClass{
  margin-top: 35px;
}

.partner-forms .tabs-forms .term-condition{
  /* font-family: Montserrat-Bold; */
  color: #ffca3d !important;
}


/* .partner-forms .tabs-forms .term-condition{
  font-family: Montserrat-Bold;
  color: #ffca3d;
}

*/
.partner-forms .form-tab .tabs-forms a.term,.term-condition{
  color: #FFCA3D;
} 

.partner-forms .tabs-forms .formBtn {
  background-color: #d94e0c;
  box-shadow: 6px 7px 18px rgba(217, 78, 12, 0.35);
}

.partner-forms .tabs-forms .formBtn:hover {
  background: #ffca3d;
  box-shadow: 6px 7px 18px rgba(2, 12, 83, 0.15);
}

.partner-forms .tabs-forms .form-control.is-invalid{
  border: 2px solid #dc3545 !important;
  box-shadow: none;
  background-image: none;
}

.partner-forms .tabs-forms .form-control.is-valid{
 border-color: transparent;
  box-shadow: none;
  background-image: none;
}

.partner-forms .tabs-forms .form-select.is-invalid:not([multiple]):not([size]){
  border: 2px solid #dc3545 !important;
  box-shadow: none;
  background-image: none;
  padding-right: 2.125rem;
}
.partner-forms .tabs-forms .form-select.is-valid:not([multiple]):not([size]){
  border-color: transparent;
  box-shadow: none;
  background-image: none;
}
/* 
.partner-forms .tabs-forms .form-select.is-valid:not([multiple]):not([size]) > option:first-child{
  color:#dc3545;
} */

.partner-forms .tabs-forms .form-control.is-invalid::placeholder {
  color: #DC4444;
}

.partner-forms .tabs-forms .invalid-feedback{
  color: #DC4444;
  font-family: Montserrat-Light;
  font-style: italic;
}

.partner-forms .tabs-forms .invalid-feedback{
  display: block !important;
  width: 100% !important;
  margin-top: 0.25rem !important;
  font-size: 80% !important;
  color: #DC4444 !important;
}

.partner-forms .tabs-forms .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.partner-forms .tabs-forms .spinner-border{
  border: 0.25em solid rgba(45, 41, 41, 0.3);
  border-top-color: #020C53;
  border-right-color: #020C53;
  vertical-align: -0.3em;
  height: 20px;
  width: 20px;
}

@media screen and (min-width: 280px) and (max-width: 767px) {
  .partner-forms .form-tab .nav-tabs .nav-link{
    font-size: 14px;
  }
  .partner-forms .tabs-forms .form-check .form-check-label {
    font-family: Montserrat-Light;
    font-size: 12px;
    text-align: start;
  }

  .partner-forms .tabs-forms .form-label {
    font-size: 15px;
  }

  .partner-forms .tabs-forms .form-control,
.form-select {
  height: 45px;
}

.partner-forms .tabs-forms .form-control::placeholder {
  font-size: 14px;
}
.partner-forms .tabs-forms .invalid-feedback{
  font-size: 75% !important;
}
  .checkbox-layout {
    display: flex;
  }
  
  .partner-forms .tabs-forms .marginTopClass{
    margin-top: 0px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .partner-forms .tabs-forms .marginTopClass{
    margin-top: 0px;
  }

}