@font-face {
    font-family: desc;
    src: url("../../../fonts/Montserrat-Light.ttf");
  }

.ad-mobileapp{
    background-color: #020C53;
    padding-top: 30px;
    
}

.ad-mobileapp .leftContent{
    width: 35rem;
    font-family: desc;
    font-weight:600;
}

.ad-mobileapp .rightImg{
    width: 21rem;
}

@media screen and (min-width: 280px) and (max-width: 767px) {
    .ad-mobileapp .rightImg{
        width: 100%;
    }
    .ad-mobileapp{
        padding-top: 0;
    }
    .ad-mobileapp .leftContent{
        font-weight:500;
    }

}