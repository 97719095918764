.footerCopyRight {
  background-color: #121212;
  color: #9f9f9f;
  font-weight: 600;
  padding: 30px;
}

.footerSocialMedia {
  background-color: #050505;
  padding: 6px;
}

ul.social-network {
  list-style: none;
  display: inline;
  margin-left: 0 !important;
  padding: 0;
}

ul.social-network li {
  display: inline;
  margin: 0 5px;
}

.social-network a.icoFacebook:hover i,
.social-network a.icoLinkedin:hover i {
  color: #fff;
}

.social-network a.socialIcon:hover,
.socialHoverClass {
  color: #44bcdd;
}

.social-circle li a {
  display: inline-block;
  position: relative;
  margin: 0 auto 0 auto;
  text-align: center;
  width: 30px;
  height: 30px;
  font-size: 15px;
}

.social-circle li i {
  margin: 0;
  line-height: 30px;
  text-align: center;
}

.social-network a img {
  width: 14px;
}

.footerSocialMedia .scrollButton {
  right: 1%;
  bottom: 1%;
}

.footerSocialMedia .scrollButton button {
  border-color: transparent;
  background-color: #d94e0c;
  height: 60px;
  width: 60px;
}

@media (min-width: 230px) and (max-width: 1023px) {
  .footerCopyRight .footerCopyRightTextAlign {
    text-align: center;
    font-size: 12px;
  }

  .footerCopyRight .font {
    font-size: 12px;
  }
  .footerCopyRight .textalign {
    text-align: center;
  }
  .footerSocialMedia .textalign {
    text-align: center;
  }
}

@media (min-width: 1024px) {
  .footerCopyRight .footerCopyRightTextAlign {
    text-align: left;
  }
  .footerCopyRight .textalign {
    text-align: end;
  }
  .footerSocialMedia .textalign {
    text-align: right;
  }
  .footerSocialMedia .scrollButton {
    right: 1%;
    bottom: 1%;
  }
  .footerCopyRight .font {
    font-size: 14px;
  }
  .footerCopyRight .footerCopyRightTextAlign {
    font-size: 14px;
  }
}
